<template>
	<!-- 问题意见清单 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">旁站</div>
			<div class="line"></div>
			<el-row
				style="margin-top: -8px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: 10px;"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="pageSpan" v-if="showAdd" style="margin-left:15px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small"
							@click="addOrUpdateHandle()">登记</el-button>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -5px;"></div>
				<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
					<el-row
						style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
						<el-col :span="18" style="margin-left:20px">
							<!-- <el-form-item label="标的物" class="selFormItem">
								<el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择">
									<el-option v-for="item in $store.state.common.navBarSubjectList"
										:key="item.displayValue" :label="item.displayName" :value="item.displayValue">
									</el-option>
								</el-select>
							</el-form-item> -->
							<el-form-item label="旁站时间" class="selFormItem" style="margin-top:2px">
								<el-date-picker class="selDateInput" v-model="timeArr" type="daterange" range-separator="~"
									value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
							<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
								v-if="showSearch">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 500px;" body-style="height:460px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="440" :row-class-name="tableRowClassName" class="dataListTable"
								:data="dataList" header-align="center" default-expand-all
								style="width: 100%;margin-bottom: 20px;" border :cell-style="{ padding: '0px' }"
								row-key="id" header-cell-class-name="dataListTHeader">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" width="80" label="标的物" show-overflow-tooltip
									header-align="center" align="left">
								</el-table-column> -->
								<el-table-column prop="onsiteItem" label="旁站项目" header-align="center" align="left">
								</el-table-column>
								<el-table-column prop="onsitePosition" label="旁站部位" show-overflow-tooltip
									header-align="center" align="left">
								</el-table-column>
								<el-table-column prop="beginTime" label="开始时间 " align="center">
								</el-table-column>
								<el-table-column prop="bystander" label="旁站人" header-align="center" align="left">
								</el-table-column>
								<el-table-column prop="onsiteContent" label="旁站内容" show-overflow-tooltip
									header-align="center" align="left">
								</el-table-column>
								<el-table-column prop="participants" label="参与人" show-overflow-tooltip header-align="center"
									align="left">
								</el-table-column>
								<el-table-column label="操作" width="250" v-if="showEdit || showDelete" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.recId)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recId)" v-if="showDelete">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
	</div>
</template>
<script>
import AddOrUpdate from './station-add-or-update'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			tabIndex: "0",
			addOrUpdateVisible: false,
			subjectList: [],
			subjectNo: "",
			category: "",
			timeArr: [],
			dataList: [],
			tableSelVal: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			form: {}
		};
	},
	components: {
		AddOrUpdate
	},
	mounted() {
		this.category = this.$route.query.type;
		this.showSearch = $common.isAuth('station:search:' + this.category);
		this.showAdd = $common.isAuth('station:add:' + this.category);
		this.showEdit = $common.isAuth('station:edit:' + this.category);
		this.showDelete = $common.isAuth('station:delete:' + this.category);
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		// if (this.showSearch) {
		// 	this.getDataList();
		// }
		this.pageIndex2 = 1;
		this.getSubjectDropDown();
	},
	methods: {
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 250;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		selGetDataList() {
			this.page = 1;
			this.getDataList();
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList();
		},
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/onsiteinfo/list",
				method: "get",
				params: {
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
					beginTime: this.timeArr[0] ? this.timeArr[0] : "",
					endTime: this.timeArr[1] ? this.timeArr[1] : "",
					category: this.category,
					limit: this.pageSize,
					page: this.pageIndex
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				}
			});
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.category, this.subjectNo)
			})
		},
		deleteHandle(id) {
			this.$confirm(`确定对[id=${id}]进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/onsiteinfo/delete",
					method: 'post',
					// data: ids,
					data: [id]
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		setSubject(subject) {
			this.subjectNo = subject.displayValue
			this.getDataList()
		},
		resetFormData() {
			// this.subjectNo = "";
			this.timeArr = [];
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSubjectInput .el-input__inner) {
	width: 120px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
